.mention {
    color: #404fe3;
    text-decoration: none;
}

.mentionSuggestions {

}

.mentionSuggestionsEntryContainer {
    display: table;
    width: 100%;
}

.mentionSuggestionsEntryContainerLeft,
.mentionSuggestionsEntryContainerRight {
    display: table-cell;
    vertical-align: middle;
}

.mentionSuggestionsEntryContainerRight {
    width: 100%;
    padding-left: 8px;
}

.mentionSuggestionsEntry {
    padding: 7px 10px 3px 10px;
    transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
}

.mentionSuggestionsEntry:active {
    background-color: #eee;
}

.mentionSuggestionsEntryFocused {
    composes: mentionSuggestionsEntry;
    background-color: #eee;
    cursor: pointer;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mentionSuggestionsEntryText {
}

.mentionSuggestionsEntryTitle {
    font-size: 80%;
    color: #a7a7a7;
}

.mentionSuggestionsEntryAvatar {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}