// Evrima colours

$reds200: #FDE4E8FF;
$reds500:#d63653;
$reds600:#a1223f;
$reds800: #82102a;
$purples10:#F8F8FB;
$purples50: #eaebfd;
$purples200:#5d6bc1; // links
$purples500: #2f3bb7;
$purples600:#1b2967;
$successa100:#b9f6ca;
$successa200:#69f0ae;
$successa700:#00af49;
$dangera100:#fbe937;
$dangera200:#ffab91;
$dangera300:#d84315;
$warninga50: #fff5ed;
$warninga100:#f68a31;
$warninga500:#ffb500;

$darklevel01:#111936;
$darklevel02:#a1223f;
$darklevel03:#212946;
$darklevel04:#29314f;
$darktypo:#8492c4;
$darkactive:#bdc8f0;

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey400: #d1d1d1;
$grey500: #c8c8c8;
$grey600: #8A8A8A;
$grey700: #616161;
$grey900: #212121;

$black:#000000;
$white:#ffffff;

// evrilink palette
$evPrimary: #404fe3;
$evSecondary: #ff9b6c;
$evGray: #f1f1f2;
$evSecondaryLight: #fce8d6;
$evPrimaryLight:#ecedfc;

// paper & background
$paper:$white;
$background:$evGray; //#f6f6f9

// primary
$primaryExtraLight: $purples10;
$primaryLight: $evPrimaryLight;
$primaryMain: $evPrimary;
$primaryDark: $purples500;
$primary200: $purples200;
$primary800: $purples600;

// secondary
$secondaryLight: #fdf3ee;
$secondaryMain: $evSecondary;
$secondaryDark: #a05735;
$secondary200: #faa67f;
$secondary800: #7d452b;

// success Colors
$successLight: #EDF7ED;
$success200: #B6E0B3;
$successMain: #6CC067;
$successDark: #00AF49;

// error
$errorLight: $reds200;
$errorMain: $reds600;
$errorDark: $reds800;

// orange
$orangeLight: $dangera100;
$orangeMain: $dangera200;
$orangeDark: $dangera300;

// warning
$warningLight: $warninga50;
$warningMain: $warninga100;
$warningDark: $warninga500;


//info
$infoLight: #ecedfc;
$infoMain: #2d379f;
$infoDark: #0d102d;





//-----------------------|| JAVASCRIPT ||-----------------------//

:export {
    fontFamily : "font-family: "Poppins", "Franklin Gothic Medium", Tahoma, sans-serif;";
    // paper & background
    paper: $paper;
    background : $background;

    // primary
    primaryExtraLight: $primaryExtraLight;
    primaryLight: $primaryLight;
    primary200: $primary200;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primary800: $primary800;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;
    secondary800: $secondary800;

    // success
    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successDark: $successDark;

    // error
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;

    // warning
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    infoLight: $infoLight;
    infoMain: $infoMain;
    infoDark: $infoDark;

    // grey
    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey400: $grey400;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey900: $grey900;
    black : $black;
    white: $white;

    // patient status

    eligible: #293689;
    disqualified:#C11B39;
    pendingcontact:#FFC107;
    referred:#00AF49;
    enrolled:#757575;
    noteligible:#D84315;

    // trial status

    recruiting: #FFC107;
    ethicsapproved:#00AF49;
    trialcreated:#293689;
    closed:#757575;
    blocked:#D84315;


}
